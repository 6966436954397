import { Bonus } from 'common/api/search/models/CanonicalSearchResultBrand';
import { SupportedType } from 'common/api/search/models/SupportedType';

export type ConstructorFacet = { name: string; values: SupportedType[] };

export type ConstructorReturnObject = {
    matched_terms: string[];
    data: {
        id: string;
        url: string;
        Bonus: Bonus[];
        group_ids: string[];
        image_url: string;
        IsFeatured: boolean;
        IsLocalProduct: boolean;
        IsOnBonus: boolean;
        MaxRebate: number;
        PopularityRank: number;
        ProductIds: number[];
        Rebate: number[];
        UrlSlug: string;
        facets: ConstructorFacet[];
        NewBannerExpiration?: string;

        // flipgive data
        AffiliateBonus?: number;
        AffiliateBonusEnd?: string;
        AffiliateBonusStart?: string;
        AffiliateExclusionText?: string;
        AffiliateOfferEarnText?: string;
        AffiliateOfferTerms?: string;
        AffiliateOfferUrl?: string;
        AffiliateRebate?: number;
        AffiliateVariablePricingText?: string;
        CanDoubleDip: boolean;
        ContainsAffiliateOffer: boolean;
        ContainsVariableRebate: boolean;
        IsAffiliateOfferOnBonus: boolean;
        IsRaiseRightBrand: boolean;
        IsRaiseRightGiftCardOnBonusOrAffiliateOnBonus: boolean;
    };
    value: string;
    is_slotted: boolean;
    labels: { [key: string]: any };
    result_id: string;
};

export enum EarnTypeConstructorFilter {
    GiftCards = 'Gift Cards',
    ShopOnline = 'Online',
}

import { batchActions } from 'redux-batched-actions';
import { Dispatch } from 'redux';
import omit from 'lodash.omit';

import { createSlice } from 'common/modules/create-slice';
import { Card, emptyCard, isCardArchivable } from 'common/api/e-comm/models/Card';
import { makeLens } from 'common/modules/lens/makeLens';
import { FeaturesState } from 'common/features/featuresReducer';
import { selectUserId } from 'common/features/store/duck/account/duck';
import { upsertBrands, upsertReloadProducts } from 'common/features/store/duck/brands/duck';
import {
    getWalletAndAssociatedBrands,
    updateBalance,
    getCardBalance,
    deleteCard,
    setViewedDate,
    unarchiveCard,
} from 'common/api/wallet/cards';
import { AsyncActionState, initialAsyncActionState } from 'common/modules/async-actions/core';
import { createApiThunk } from 'common/modules/async-actions/thunk';
import { getReloadProduct } from 'common/api/e-comm/brands/reload';
import { RemoteCardDetails } from 'common/api/e-comm/models/RemoteCardDetails';
import { createSelector } from 'reselect';

export type CardsById = Partial<{
    [cardId: string]: Card;
}>;

export type BarcodesById = Partial<{
    [cardId: string]: string;
}>;

export interface WalletState {
    cardsByUser: Partial<{
        [userId: string]: CardsById;
    }>;
    asyncState: AsyncActionState;
    barcodesById: BarcodesById;
    remoteCardDetailsById: Partial<{ [cardId: string]: RemoteCardDetails }>;
    getBalanceAsyncState: AsyncActionState;
    archived: Partial<{
        [userId: string]: Partial<{
            [cardId: string]: Card;
        }>;
    }>;
    affiliateOffersForWalletCardsAsyncState: AsyncActionState;
    affiliateOffersForWalletCards?: Partial<{
        [brandId: string]: {
            name: string;
            bestRebate: number;
            bonusEnd: string;
            bonusStart: string;
            canDoubleDip: boolean;
            id: string;
            url: string;
            variable: boolean;
        };
    }>;
}

export const initialWalletState: WalletState = {
    cardsByUser: {},
    asyncState: initialAsyncActionState,
    getBalanceAsyncState: initialAsyncActionState,
    archived: {},
    barcodesById: {},
    remoteCardDetailsById: {},
    affiliateOffersForWalletCardsAsyncState: initialAsyncActionState,
    affiliateOffersForWalletCards: {},
};

const { update, reducer, configureAction } = createSlice(initialWalletState, 'WALLET');
export const walletReducer = reducer;

const lens = makeLens<WalletState>();

export const upsertCard = configureAction<{ userId: string; card: Card }>(
    'UPSERT_CARD',
    ({ userId, card }) =>
        lens
            .k('cardsByUser')
            .k(userId)
            .set((cards) => ({ ...cards, [card.id]: card }))
);

export const setCards = configureAction<{ userId: string; cards: { [id: string]: Card } }>(
    'UPSERT_CARDS',
    ({ userId, cards }) =>
        lens
            .k('cardsByUser')
            .k(userId)
            .set(() => cards)
);

export const setAffiliateOffersForWalletCards = configureAction<{ [brandId: string]: any }>(
    'SET_AFFILIATE_OFFERS_FOR_WALLET_CARDS',
    (affiliateOffersForWalletCards) =>
        lens.k('affiliateOffersForWalletCards').set(() => affiliateOffersForWalletCards)
);

interface SetCardBalancePayload {
    userId: string;
    cardId: string;
    cardBalance: number | null;
    balanceUpdated: string | null;
}

export const setCardBalance = configureAction<SetCardBalancePayload>(
    'SET_CARD_BALANCE',
    ({ userId, cardId, cardBalance, balanceUpdated }) =>
        lens
            .k('cardsByUser')
            .k(userId)
            .set((cards) => {
                const card = cards && cards[cardId];
                return !card
                    ? cards
                    : { ...cards, [cardId]: { ...card, cardBalance, balanceUpdated } };
            })
);

export const upsertBarcodes = configureAction<{ [id: string]: string }>(
    'UPSERT_BARCODES',
    (barcodesById) => (s) => ({ ...s, barcodesById: { ...s.barcodesById, ...barcodesById } })
);

export const deleteBarcodes = configureAction<string[]>('DELETE_BARCODE', (ids) =>
    lens.k('barcodesById').set((barcodesById) => omit(barcodesById, ...ids))
);

export const upsertRemoteCardDetails = configureAction<
    Partial<{ [cardId: string]: RemoteCardDetails }>
>('UPSERT_REMOTE_CARD_DETAILS', (remoteCardDetailsById) =>
    lens.k('remoteCardDetailsById').set((s) => ({ ...s, ...remoteCardDetailsById }))
);

export const deleteRemoteCardDetails = configureAction<string[]>(
    'DELETE_REMOTE_CARD_DETAILS',
    (ids) =>
        lens
            .k('remoteCardDetailsById')
            .set((remoteCardDetailsById) => omit(remoteCardDetailsById, ...ids))
);

export const decommissionCard = configureAction<{ userId: string; cardId: string }>(
    'DECOMMISSION_CARD',
    ({ userId, cardId }) =>
        (s) => {
            let newState = lens
                .k('cardsByUser')
                .k(userId)
                .set((cards) => omit(cards, cardId))(s);
            const card = (s.cardsByUser[userId] || {})[cardId];
            if (card && isCardArchivable(card)) {
                newState = lens
                    .k('archived')
                    .k(userId)
                    .set((cards) => ({ ...cards, [cardId]: card }))(newState);
            }
            return newState;
        }
);

export const restoreCard = configureAction<{ userId: string; cardId: string }>(
    'RESTORE_CARD',
    ({ userId, cardId }) =>
        (s) => {
            let newState = lens
                .k('archived')
                .k(userId)
                .set((cards) => omit(cards, cardId))(s);
            const card = (s.archived[userId] || {})[cardId];
            if (card) {
                newState = lens
                    .k('cardsByUser')
                    .k(userId)
                    .set((cards) => ({ ...cards, [cardId]: card }))(newState);
            }
            return newState;
        }
);

export const requestAffilateOffersForWalletCards =
    (brandIds: number[], constructorClient: any) => (dispatch: Dispatch) => {
        if (!brandIds || brandIds.length === 0 || !dispatch || !constructorClient) {
            return;
        }

        update({
            affiliateOffersForWalletCardsAsyncState: {
                ...initialAsyncActionState,
                loading: true,
            },
        });

        constructorClient.browse
            .getBrowseResultsForItemIds(brandIds, { resultsPerPage: 200 })
            .then((data: any) => {
                const results = data.response.results.map((result: any) => {
                    return { ...result.data, Name: result.value };
                });
                const mappedResults = results.map((result: any) => {
                    return {
                        name: result.Name,
                        bestRebate: result.AffiliateBonus || result.AffiliateRebate,
                        bonusEnd: result.AffiliateBonusEnd,
                        bonusStart: result.AffiliateBonusStart,
                        canDoubleDip: result.CanDoubleDip,
                        id: result.id,
                        url: result?.AffiliateOfferUrl,
                        variable:
                            result?.ContainsVariableRebate || result?.AffiliateVariablePricingText,
                    };
                });

                const resultsById = brandIds.reduce((acc: any, id: any) => {
                    const result = mappedResults.find((r: any) => `${r.id}` === `${id}`);
                    acc[id] = { ...result };
                    return acc;
                }, {});

                dispatch(setAffiliateOffersForWalletCards(resultsById));
                update({
                    affiliateOffersForWalletCardsAsyncState: {
                        ...initialAsyncActionState,
                        loading: false,
                    },
                });
            })
            .catch((error: any) => {
                update({
                    affiliateOffersForWalletCardsAsyncState: { ...initialAsyncActionState, error },
                });
            });
    };

export const requestWallet = createApiThunk(
    (userId: string, brandIds?: number[]) => getWalletAndAssociatedBrands(userId, brandIds),
    () => (state, result) => {
        return result
            ? batchActions([
                  upsertBrands(result.brands),
                  upsertReloadProducts(result.reloadProducts),
                  setCards(result.cards),
                  update({ asyncState: state }),
              ])
            : update({ asyncState: state });
    }
);

export const updateBalanceThunk = (userId: string, cardId: string, cardBalance: number) => {
    return async (dispatch: Dispatch) => {
        const balanceUpdated = new Date().toISOString();
        const response = await updateBalance(cardId, cardBalance);

        if (!response.error) {
            dispatch(setCardBalance({ userId, cardId, cardBalance, balanceUpdated }));
        }

        return response;
    };
};

export const getCardBalanceThunk = createApiThunk(
    (_userId: string, cardId: string) => getCardBalance(cardId),
    (userId, cardId) => (getBalanceAsyncState, data) =>
        batchActions([
            update({ getBalanceAsyncState }),
            ...(data ? [setCardBalance({ userId, cardId, ...data })] : []),
        ])
);

export const getReloadProductThunk = createApiThunk(
    getReloadProduct,
    (brandId) => (_asyncState, product) => {
        if (product) {
            return upsertReloadProducts({ [brandId]: product });
        }
    }
);

export const selectUserCards = createSelector(
    (s: FeaturesState) => selectUserId(s),
    (s: FeaturesState) => s.store.wallet.cardsByUser,
    (userId: string, cardsByUser: WalletState['cardsByUser']) => cardsByUser[userId] || {}
);

export const selectUserCard = (userId: string, cardId: string) => (s: FeaturesState) => {
    const cards = s.store.wallet.cardsByUser[userId];

    if (!cards) {
        return emptyCard;
    }

    return cards[cardId] || emptyCard;
};

export const deleteCardThunk = (userId: string, cardId: string) => async (dispatch: Dispatch) => {
    const response = await deleteCard(cardId);
    if (!response.error) {
        dispatch(decommissionCard({ userId, cardId }));
    }
    return response;
};

export const unarchiveCardThunk =
    (userId: string, cardId: string) => async (dispatch: Dispatch) => {
        const response = await unarchiveCard(cardId);
        if (!response.error) {
            dispatch(restoreCard({ userId, cardId }));
        }
        return response;
    };

const viewCard = configureAction<{ userId: string; cardId: string }>(
    'VIEW_CARD',
    ({ userId, cardId }) =>
        lens
            .k('cardsByUser')
            .k(userId)
            .set((cards) => {
                const card = cards && cards[cardId];
                if (!card) {
                    return cards;
                }

                return {
                    ...cards,
                    [cardId]: { ...card, lastViewedDate: new Date().toISOString() },
                };
            })
);

export const viewCardThunk = (userId: string, cardId: string) => async (dispatch: Dispatch) => {
    dispatch(viewCard({ userId, cardId }));
    setViewedDate(cardId);
};

export const resetWallet = () => update({ getBalanceAsyncState: initialAsyncActionState });

export const selectWalletIsLoading = createSelector(
    (s: FeaturesState) => s.store.wallet.asyncState,
    (asyncState) => asyncState.loading
);

export const selectWalletAffiliateBonuses = createSelector(
    (s: FeaturesState) => s.store.wallet.affiliateOffersForWalletCards,
    (affiliateOffersForWalletCards) => {
        return (brandId: string) => {
            const record = affiliateOffersForWalletCards?.[brandId];

            return record?.canDoubleDip ? record : null;
        };
    }
);

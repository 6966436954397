import React, { useContext } from 'react';
import { StylesContext } from '../../features/search/components/contexts';
import { createStyles } from '../../utils/createStyle';
import './BrandCardSlim.scss';

const style = createStyles({
    container:
        'text-brand-dark text-center flex flex-row justify-center items-baseline whitespace-pre',
    productType: [
        'font-normal',
        {
            fontSize: '12px',
            lineHeight: '18px',
            '@media (min-width: 640px)': {
                fontSize: '14px',
                lineHeight: '22px',
                paddingBottom: 2,
            },
        },
    ],
    singleEarnings: [
        'text-brand-dark font-normal text-right flex flex-col items-center',
        {
            fontSize: '12px',
            lineHeight: '18px',
            paddingBottom: 2,
            '@media (min-width: 640px)': {
                fontSize: '14px',
                lineHeight: '22px',
            },
        },
    ],
    earnings: [
        'm-0 p-0 font-semibold',
        {
            fontSize: '16px',
            lineHeight: '24px',
            '@media (min-width: 640px)': {
                fontSize: '18px',
                lineHeight: '26px',
                paddingBottom: 2,
            },
        },
    ],
    smallText: [
        'font-normal text-grey-2',
        {
            fontSize: '12px',
            lineHeight: '18px',
            '@media (min-width: 640px)': {
                fontSize: '11px',
                lineHeight: '16px',
            },
        },
    ],
    previousBonus: [
        {
            textDecoration: 'line-through',
        },
    ],
});

export interface Earnings {
    isBonus?: boolean;
    earn: { previous?: number; actual?: number };
    hasMultipleRebates?: boolean;
    rebateMessage: string;
    singleTypeView: boolean;
}

export const Earnings = ({
    isBonus = false,
    earn,
    hasMultipleRebates,
    rebateMessage,
    singleTypeView,
}: Earnings) => {
    const { styles } = useContext(StylesContext);
    if (!earn.actual) {
        return null;
    }

    const getSingleTypeView = () => (
        <div className={`${style.singleEarnings} ${styles.earnings}`}>
            <div className="flex-row">
                <span className={`${style.productType} m-0 p-0 text-grey-2`}>Earn</span>
                <span className={`${style.productType} font-semibold`}>
                    {` ${hasMultipleRebates ? 'up to ' : ''}`}
                </span>
                <span className={`${style.earnings} ${styles.earnings}`}>
                    {` ${Math.abs(earn.actual ?? 0)}% `}
                </span>
            </div>
            {isBonus && (
                <span className={style.smallText}>
                    was{' '}
                    <span className={`${style.previousBonus}`}>
                        {Math.abs(earn.previous ?? 0)}%
                    </span>
                </span>
            )}
        </div>
    );

    const getDoubleTypeView = () => (
        <div className={style.container}>
            <span className={`${style.productType} text-grey-2`}>{rebateMessage}</span>
            <span className={`${style.productType} font-semibold`}>
                {` ${hasMultipleRebates ? 'up to ' : ''}`}
            </span>
            <span className={`${style.earnings} ${styles.earnings}`}>
                {`${Math.abs(earn.actual ?? 0)}% `}
            </span>
            {isBonus && !hasMultipleRebates && (
                <span className={style.smallText}>
                    <span className={`${style.previousBonus}`}>
                        {Math.abs(earn.previous ?? 0)}%
                    </span>
                </span>
            )}
        </div>
    );

    return singleTypeView ? getSingleTypeView() : getDoubleTypeView();
};

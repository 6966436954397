import React, { useEffect, useState, useMemo } from 'react';
import { getFeaturedBrands } from 'common/api/search/getFeaturedBrands';
import { APIResponse } from 'common/api/models';
import { SearchResult } from 'common/api/search/models/SearchResult';
import {
    Brand,
    searchResultBrandsToBrands,
} from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import Skeleton from 'react-loading-skeleton';
import { Btn } from 'components/btn/Btn';
import { css } from 'glamor';
import { colors } from 'styles/settings';
import { createStyles } from 'utils/createStyle';
import useMediaQueries from 'hooks/useMediaQueries';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { BrandCardSlim } from 'components/brand-card/BrandCardSlim';
import { convertBrandToCardProps } from 'components/bonus/CardProps';
import { useRootSelector } from 'common/features/featuresReducer';
import { editFilter } from 'common/utils/shipToCoordinatorUtils';
import { useGlobalBrandFilters } from 'common/hooks/useGlobalBrandFilters';
import { UserAuthState } from 'common/features/store/duck/account/duck';
import { selectIsShipToCoordinatorEnabled } from 'common/features/store/duck/organization/duck';
import { useSelector } from 'react-redux';

const RenderFeaturedBrands = ({ cards }: { cards: Brand[] }) => {
    const desktopCardWidth = 180;
    const [cardsToShow, setCardsToShow] = useState<number>(6);
    const top6 = cards.slice(0, cardsToShow);
    const [cardWidth, setCardWidth] = useState<number>(desktopCardWidth);

    const cardStyle = createStyles({
        cardContainer: [
            {
                width: `${cardWidth}px`,
            },
        ],
    });

    const styles = createStyles({
        container: [
            'grid justify-center gap-3',
            {
                '@media (min-width: 780px)': {
                    gridTemplateColumns: `repeat(6, ${cardWidth}px)`,
                },
                '@media (max-width: 1162px)': {
                    gridTemplateColumns: `repeat(3, ${cardWidth}px)`,
                },
                '@media (max-width: 840px)': {
                    gridTemplateColumns: `repeat(2, ${cardWidth}px)`,
                },
                '@media (max-width: 570px)': {
                    gridTemplateColumns: `repeat(2, ${cardWidth}px)`,
                },
            },
        ],
    });

    const queries = useMemo(
        () => ({
            '(max-width: 570px)': () => {
                setCardWidth(150);
                setCardsToShow(6);
            },
            '(min-width: 571px)': () => {
                setCardWidth(180);
                setCardsToShow(6);
            },
        }),
        []
    );

    useMediaQueries(queries);

    return (
        <div>
            <div className="flex justify-center">
                <div className={styles.container}>
                    {top6.map((card: Brand) => (
                        <BrandCardSlim
                            key={card.id}
                            styles={cardStyle}
                            data={convertBrandToCardProps(card, undefined, true)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export const FeaturedSection = () => {
    const [featuredBrands, setFeaturedBrands] = useState<Brand[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const globalFilter = useGlobalBrandFilters();
    const isShipToCoordinatorEnabled = useSelector(selectIsShipToCoordinatorEnabled);
    const notAuthenticated = useRootSelector(
        (s) => s.store.account.userAuthState === UserAuthState.NOTAUTHENTICATED
    );
    const filter = editFilter(isShipToCoordinatorEnabled, globalFilter) as string;

    useEffect(() => {
        // NOTE: fetch once we know that the `isShipToCoordinatorEnabled` has been loaded
        // - avoid calling loadData multiple times by checking brands length
        if (
            (notAuthenticated || isShipToCoordinatorEnabled !== undefined) &&
            !featuredBrands?.length
        ) {
            getFeaturedBrands({ filter })
                .then((response: APIResponse<SearchResult, null>) => {
                    setFeaturedBrands(
                        searchResultBrandsToBrands(
                            response.data ?? ({ brands: [] } as SearchResult)
                        )
                    );
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [featuredBrands?.length, filter, notAuthenticated, isShipToCoordinatorEnabled]);

    if (loading) {
        return <Skeleton className="h-80" />;
    }

    // Do not render anything if there are no featuredBrands fetched OR if there's an error
    if ((error || featuredBrands.length === 0) && !loading) {
        return null;
    }

    return (
        <FeaturedSectionRenderer results={featuredBrands.length}>
            <RenderFeaturedBrands cards={featuredBrands} />
        </FeaturedSectionRenderer>
    );
};

interface FeaturedSectionRendererProps {
    results: number;
}

const Title = ({ children }: React.PropsWithChildren) => {
    return (
        <div className="mb-10">
            <p className="text-brand-dark text-3xl font-semibold">{children}</p>
        </div>
    );
};

const FeaturedSectionRenderer = ({
    results,
    children,
}: React.PropsWithChildren<FeaturedSectionRendererProps>) => {
    const history = useHistory();

    const navToFeatures = () => {
        history.push(`${Routes.Featured}`);
    };

    return (
        <div className="shop_featured_brands w-full p-5 flex items-center flex-col">
            <Title>Featured Gift Card Brands</Title>
            {children}
            <Btn onClick={navToFeatures} className="mt-10">
                See All {results}
            </Btn>
        </div>
    );
};

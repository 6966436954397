import { findBonusRebate, findMaxRebate, hasMultipleRebates } from '../../brands/utils/rebates';

import { SearchResult } from 'common/api/search/models/SearchResult';

export interface Brand {
    id: string;
    name: string;
    rebate: number;
    isOnBonus: boolean;
    isFeatured: boolean;
    imageUrl: string;
    keyWords: string[];
    bonusRebate?: number;
    expiration?: string;
    hasMultipleRebates?: boolean;
    isLocalProduct: boolean;
    productIds: number[];
    supportedTypes?: string[];
    categories: string[];
    suggestions?: string[];
    urlSlug?: string;
    newBannerExpiration: Date | null;
    isNew?: boolean;
    isSponsored?: boolean;

    // flipgive data
    affiliateBonus?: number;
    affiliateBonusEnd?: string;
    affiliateBonusStart?: string;
    affiliateExclusionText?: string;
    affiliateOfferEarnText?: string;
    affiliateOfferTerms?: string;
    affiliateOfferUrl?: string;
    affiliateRebate?: number;
    affiliateVariablePricingText?: string;
    canDoubleDip: boolean;
    containsAffiliateOffer: boolean;
    containsVariableRebate?: boolean;
    isAffiliateOfferOnBonus: boolean;
    isRaiseRightBrand: boolean;
    isRaiseRightGiftCardOnBonusOrAffiliateOnBonus: boolean;
}

export const searchResultBrandsToBrands = (
    result: SearchResult,
    affiliateOnly?: boolean
): Brand[] => {
    return result.brands.map((brand) => {
        const bonus = findBonusRebate(brand.bonus);

        if (affiliateOnly) {
            return {
                ...brand,
                rebate: findMaxRebate(brand.rebate),
                bonusRebate: bonus ? bonus.rebate : undefined,
                expiration: bonus ? bonus.expiration : undefined,
                hasMultipleRebates: undefined,
                isNew:
                    !!brand.newBannerExpiration && new Date(brand.newBannerExpiration) > new Date(),
                isSponsored: brand.isSponsored,
                isOnBonus: brand.isAffiliateOfferOnBonus,
            };
        }
        return {
            ...brand,
            rebate: findMaxRebate(brand.rebate),
            bonusRebate: bonus ? bonus.rebate : undefined,
            expiration: bonus ? bonus.expiration : undefined,
            hasMultipleRebates: hasMultipleRebates(brand),
            isNew: !!brand.newBannerExpiration && new Date(brand.newBannerExpiration) > new Date(),
            isSponsored: brand.isSponsored,
            isOnBonus: brand.isOnBonus,
        };
    });
};
